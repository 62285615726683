html,
body {
  height: 100%;
}

body {
  font-family: $font-body;
  font-size: 16px;
  color: mat-color(on-surface);
  background-color: mat-color(surface-container-lowest);

  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba($black, 0); // 4
}

//app-root {
//  // weird that this is needed. But the default sys-on-surface is defined on same level so it's not available to mat-icon
//  --mat-icon-color: #{mat-color(on-surface)};
//}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button:not(:disabled) {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
  font-size: 0.85em;
  color: mat-color('on-surface');
}
