//TEMP
// Usage: color="tonal"

.mat-tonal {
  &.mat-mdc-button-base {
    background-color: var(--md-sys-color-surface-variant);
    color: var(--md-sys-color-on-surface-variant);
  }

  &.mat-mdc-chip {
    --mdc-chip-elevated-container-color: var(--md-sys-color-surface-bright);
    --mdc-chip-outline-width: 0;
  }
}

.mat-tonal-primary {
  &.mat-mdc-button-base,
  &.mat-mdc-button-base:not(:disabled) {
    //background-color: var(--md-sys-color-surface-variant);
    //color: var(--md-sys-color-on-surface-variant);

    background-color: #{mat-color(primary-container)};
    color: #{mat-color(on-primary-container)};
  }
}
