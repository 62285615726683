// menu max height
.cdk-overlay-pane {
  bottom: 30px;
  overflow: hidden;
  height: min-content;
}

.cdk-overlay-pane.autocomplete-dialog {
  margin-top: -30vh;
  padding: 10px;
}
.cdk-overlay-container {
  --mdc-dialog-container-color: var(--md-sys-color-surface-container-high);
}

.mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  @extend .custom-scrollbar;
}

.mat-mdc-dialog-container .mat-mdc-dialog-content {
  max-height: none;
}
