.cdk-drag-preview {
  border: none;
  box-sizing: border-box;
  border-radius: var(--mdc-elevated-card-container-shape);
  list-style: none;
  overflow: hidden; // hide scrollbars on windows...
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  --placeholder-border: 3px dashed var(--md-sys-color-outline);
  opacity: 0;
  border-radius: var(--mdc-elevated-card-container-shape);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
  cursor: grabbing;
}

.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-handle {
  cursor: grab !important;
}
