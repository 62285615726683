@use '@angular/material' as mat;
@include mat.elevation-classes();
@include mat.app-background();

// Helpers
@import './functions/all';
@import './config/all';

// Components
@import './components/all';

// Apply theme's

body {
  @include mat.system-level-typography($theme);
}

// DOCS: https://material.angular.io/guide/typography
@include mat.typography-hierarchy($theme);

@include theme('light') {
  @include mat.all-component-themes($theme);
  @include mat.system-level-colors($theme);

  --md-extended-color-info: rgb(61 95 144);
  --md-extended-color-on-info: rgb(255 255 255);
  --md-extended-color-info-container: rgb(213 227 255);
  --md-extended-color-on-info-container: rgb(0 28 59);

  --md-extended-color-warning: rgb(195, 97, 27);
  --md-extended-color-on-warning: rgb(255 255 255);
  --md-extended-color-warning-container: rgb(255 219 201);
  --md-extended-color-on-warning-container: rgb(50 18 0);

  --md-extended-color-success: rgb(44 106 69);
  --md-extended-color-on-success: rgb(255 255 255);
  --md-extended-color-success-container: rgb(176 241 195);
  --md-extended-color-on-success-container: rgb(0 33 15);
}
@include theme('dark') {
  @include mat.all-component-themes($dark-theme);
  @include mat.system-level-colors($dark-theme);

  --md-extended-color-info: rgb(166 200 255);
  --md-extended-color-on-info: rgb(2 49 94);
  --md-extended-color-info-container: rgb(35 71 118);
  --md-extended-color-on-info-container: rgb(213 227 255);

  --md-extended-color-warning: rgb(255 182 140);
  --md-extended-color-on-warning: rgb(111, 49, 4);
  --md-extended-color-warning-container: rgb(195, 97, 27);
  --md-extended-color-on-warning-container: rgb(255 219 201);

  --md-extended-color-success: rgb(148 213 168);
  --md-extended-color-on-success: rgb(0 57 30);
  --md-extended-color-success-container: rgb(14 81 47);
  --md-extended-color-on-success-container: rgb(176 241 195);
}

@import './themes/test';

// Components
@import './utils/all';
