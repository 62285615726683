.mat-mdc-progress-spinner.mat-light {
  --mdc-circular-progress-active-indicator-color: var(--md-sys-color-surface-bright);
}

.mdc-button__label {
  .mat-mdc-progress-spinner {
    margin-right: var(--mat-filled-button-icon-spacing, 8px);
    margin-left: var(--mat-filled-button-icon-offset, -4px);
  }
}
