.cdk-overlay-dark-backdrop {
  background-color: rgba(0, 0, 0, 0.52);

  .dark & {
    background-color: rgba(0, 0, 0, 0.62);
  }
}

.cdk-overlay-pane {
  overflow: initial;
}
