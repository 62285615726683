fstr-cookie-notice {
  --cookie-notice-bg: #{mat-color(surface-bright)};
  --cookie-notice-border: transparent;
  --cookie-notice-color: var(--md-sys-color-on-surface);
}

.fstr-cookie-notice {
  box-shadow: var(--mat-app-elevation-shadow-level-11);
  max-width: 600px !important;

  &__title {
    @extend .mat-headline-medium;
  }

  &__content {
    @extend .mat-body-medium;
  }

  &__toggle-row {
    &:first-child {
      padding-top: 20px;
    }
    &__content {
      p {
        @extend .mat-body-small;
      }
    }
  }

  .button {
    --bg: transparent;
    --overlay: var(--mat-filled-button-state-layer-color);
    --overlay-opacity: var(--mat-filled-button-hover-state-layer-opacity);

    transition: all 0.2s;
    padding: 0 var(--mat-filled-button-horizontal-padding, 16px);
    height: var(--mdc-filled-button-container-height);
    font-family: var(--mdc-filled-button-label-text-font, var(--mat-app-label-large-font));
    font-size: var(--mdc-filled-button-label-text-size, var(--mat-app-label-large-size));
    letter-spacing: var(--mdc-filled-button-label-text-tracking, var(--mat-app-label-large-tracking));
    text-transform: var(--mdc-filled-button-label-text-transform);
    font-weight: var(--mdc-filled-button-label-text-weight, var(--mat-app-label-large-weight));
    border-radius: 100px;
    background: var(--bg);

    &--settings,
    &.toggle-button {
      --overlay: var(--mat-text-button-state-layer-color);
      --overlay-opacity: var(--mat-text-button-hover-state-layer-opacity);
      color: var(--mdc-text-button-label-text-color, var(--mat-app-primary));
    }

    &--primary {
      --bg: var(--mdc-filled-button-container-color, var(--mat-app-primary));
      color: var(--mdc-filled-button-label-text-color, var(--mat-app-on-primary));
    }

    &:hover {
      --hover-overlay-color: color-mix(in srgb, var(--overlay) calc(var(--overlay-opacity) * 100%), transparent);
      background: linear-gradient(0deg, var(--hover-overlay-color) 0%, var(--hover-overlay-color) 100%), var(--bg);
    }
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
  }
}
