$side-panel-max-width: 860px;

.side-panel {
  --mdc-dialog-container-color: var(--md-sys-color-surface-container-low);
  width: 100%;

  @include media-breakpoint-up(lg) {
    --mat-dialog-container-max-width: #{$side-panel-max-width};
  }

  //box-shadow: var(--mat-app-elevation-shadow-level-13);
  border-radius: var(--mdc-dialog-container-shape, var(--mat-app-corner-extra-large, 4px)) 0 0
    var(--mdc-dialog-container-shape, var(--mat-app-corner-extra-large, 4px));
  animation: slideInFromRight 0.2s ease-out;

  //var(--mat-dialog-transition-duration, 0ms)
  .mat-mdc-dialog-container.mdc-dialog--closing {
  }

  .mat-mdc-dialog-container.mdc-dialog--opening {
  }

  .mat-mdc-dialog-surface {
    transform: translateX(30%);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid var(--md-sys-color-surface-container-low);
    border-right: 0;
  }

  //.mdc-dialog--open .mat-mdc-dialog-surface,
  //.mdc-dialog--closing .mat-mdc-dialog-surface {
  //  transform: none;
  //}

  .mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    max-height: calc(100vh - 140px);
    border-bottom: 1px solid var(--md-sys-color-outline-variant);
  }
}
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel.panel-layer-1 {
  --mat-dialog-container-max-width: calc(#{$side-panel-max-width} - 10px);
}
.cdk-overlay-pane.mat-mdc-dialog-panel.panel-layer-2 {
  --mat-dialog-container-max-width: calc(#{$side-panel-max-width} - 20px);
}
.cdk-overlay-pane.mat-mdc-dialog-panel.panel-layer-3 {
  --mat-dialog-container-max-width: calc(#{$side-panel-max-width} - 30px);
}
