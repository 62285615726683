.tab-button {
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  color: mat-color(on-surface);
  text-decoration: none;
  padding: 0.6em;

  border-radius: 7px;
  gap: 0.3em;

  .mat-icon {
    transition:
      color 0.2s,
      background-color 0.2s;
    --icon-size: 1.4em;
    width: 56px;
    border-radius: 80px;
    height: 34px;
    line-height: 34px;
  }

  &__label {
    font-size: 0.8rem;
  }

  &:hover {
    .mat-icon {
      background-color: mat-color(surface-container-high);
    }
  }

  &:focus,
  &.is--active {
    .mat-icon {
      background-color: mat-color(secondary-container);
      color: mat-color(on-secondary-container);
    }
  }
}
