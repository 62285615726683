.custom-scrollbar {
  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--md-sys-color-surface-container-highest);
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: var(--md-sys-color-surface-container-highest);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--md-sys-color-primary);
    border-radius: 10px;
  }
}

// test custom inside app
* {
  //@extend .custom-scrollbar;
}
