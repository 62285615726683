// skeleton loading state for list items
@include theme('light') {
  --shimmer-color: white;
}
@include theme('dark') {
  --shimmer-color: black;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@mixin textElements() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  span {
    @content;
  }
}

.shimmer {
  //filter: grayscale(1);
  overflow: hidden;

  &::after {
    @include abs();
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      color-mix(in srgb, var(--shimmer-color) 0%, transparent) 0,
      color-mix(in srgb, var(--shimmer-color) 20%, transparent) 20%,
      color-mix(in srgb, var(--shimmer-color) 50%, transparent) 60%,
      color-mix(in srgb, var(--shimmer-color) 0%, transparent)
    );
    animation: shimmer 2s infinite linear;
    content: '';
  }

  @include textElements() {
    color: transparent;
    position: relative;

    &::after {
      content: '';
      background: var(--shimmer-color);
      border-radius: 30px;
      display: inline-block;
      position: absolute;
      top: 20%;
      bottom: 20%;
      left: 0;
      right: 30%;
      min-width: 5ch;
    }
  }
}
