.mat-mdc-card.mdc-card {
  --mat-card-padding: #{spacer(4)};

  .mat-mdc-card-header {
    padding: var(--mat-card-padding) var(--mat-card-padding) 0;
  }

  .mat-mdc-card-content {
    padding: var(--mat-card-padding);
  }
}

.p-card {
  padding: var(--mat-card-padding);
}

.pt-card {
  padding-top: var(--mat-card-padding);
}

.pb-card {
  padding-bottom: var(--mat-card-padding);
}
