.mat-mdc-chip-highlighted {
  --mdc-chip-flat-selected-outline-width: 1px;
}

// Util class mat-chip--icon-start, can be used to place trailing icon at the start of the chip
.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action.mat-chip--icon-start {
  .mdc-evolution-chip__action--primary {
    padding-left: 0;
    padding-right: 12px;
  }
  .mdc-evolution-chip__cell--trailing {
    order: -1;
  }
}

// Custom status colors
mat-chip.mat-chip--error {
  background-color: var(--md-sys-color-error);
  color: var(--md-sys-color-on-error);

  &.mat-tonal {
    background-color: var(--md-sys-color-error-container);
    color: var(--md-sys-color-on-error-container);
  }
}

@each $color in $custom-status-colors {
  mat-chip.mat-chip--#{$color} {
    background-color: var(--md-extended-color-#{$color}-container);
    color: var(--md-extended-color-on-#{$color}-container);

    --mdc-chip-label-text-color: var(--md-extended-color-on-#{$color}-container);
    --mdc-chip-with-trailing-icon-trailing-icon-color: var(--md-extended-color-on-#{$color}-container);
    --mdc-chip-outline-color: var(--md-extended-color-on-#{$color}-container);

    &.mat-tonal {
      background-color: var(--md-extended-color-#{$color}-container);
      color: var(--md-extended-color-on-#{$color}-container);
    }
  }
}

a,
button,
fstr-link {
  .mdc-evolution-chip__action--presentational {
    cursor: pointer;
  }
}

// Fix for chips with long text
.mat-mdc-chip,
.mdc-evolution-chip__cell,
.mdc-evolution-chip__action,
.mat-mdc-chip-action-label {
  min-width: 0px;
  max-width: 100%;
}

.mat-mdc-chip-set {
  min-width: 0px;
}
