.min-width {
  min-width: 0 !important;
}

// container queries

//$display-values: 'block', 'none', 'flex', 'inline-flex', 'inline-block';

//@each $name, $bp in $grid-breakpoints {
//  @each $val in $display-values {
//    @container (min-width: #{$bp}) {
//      .display-c#{$name}-#{$val} {
//        display: $val !important;
//      }
//    }
//  }
//}
.d-contents {
  display: contents !important;
}
