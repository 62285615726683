.link-reset {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid blue;
    outline-offset: 3px;
  }
}

// for 3 items
@for $i from 1 through 4 {
  .max-lines-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    hyphens: auto;
  }
}

// Fixes cut off text at the bottom (what you will get with normal margin-bottom:0)
.mb-max-lines {
  padding-bottom: 0.1em;
  margin-bottom: -0.1em;
}

.text-truncate {
  overflow: hidden !important;
}

.font-monospace {
  font-family: monospace !important;
}

.fw-medium {
  font-weight: 500 !important;
}
.fw-semi {
  font-weight: 600 !important;
}
