.form-card-list-container {
  background-color: var(--md-sys-color-surface-container-low);
  border-radius: var(--mdc-elevated-card-container-shape);

  mat-expansion-panel & {
    background-color: var(--md-sys-color-surface-container);
  }
}

.form-card-list {
  background-color: var(--md-sys-color-surface-container-low);
  border-radius: var(--mdc-elevated-card-container-shape);
  padding: spacer(2);
  display: flex;
  flex-flow: column;
  gap: spacer(2);
  container-type: inline-size;

  mat-expansion-panel & {
    background-color: var(--md-sys-color-surface-container);
  }

  --mdc-elevated-card-container-color: var(--md-sys-color-surface-container-lowest);
}

.mat-list-card {
  --mat-card-padding: #{spacer(2)};
  --mat-card-active-border: 3px;

  border: var(--mat-card-active-border) solid transparent;
  transition: background-color 0.05s;

  &.is--active {
    border-color: var(--md-sys-color-primary);
  }

  &.is--deactivated {
    .thumb {
      filter: grayscale(1);
    }

    .mat-mdc-card-content {
      opacity: 0.4;
    }
  }
}
