.mat-mdc-radio-button {
  // For long labels its better to align the radio button to the top
  .mat-internal-form-field {
    align-items: flex-start;
  }

  .mdc-label {
    margin-top: 0.59em;
    display: inline-block;
  }
}
