.mat-mdc-list-base .mdc-list-group__subheader {
  margin: 0;
  padding: calc((3rem - 1.5rem) / 2) 16px;
}

// Add icon in list as suffix (like an arrow right)
//.mdc-list-item__content:has(.mat-list-item--action-icon) {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;

//  .mdc-list-item__primary-text {
//    margin-bottom: 0 !important;
//  }
//}

.mat-mdc-list-item-unscoped-content:empty {
  display: none;
}

.mat-mdc-list-item-title:has(.mat-list-item--action-icon) {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
