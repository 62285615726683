@use '@angular/material' as mat;

.button--tertiary {
  @include mat.button-color($theme, $color-variant: tertiary);
}

.fab--tertiary {
  @include mat.fab-color($theme, $color-variant: tertiary);
}

.mat-mdc-fab-base .mat-icon,
.mat-mdc-fab-base .material-icons {
  color: inherit;
}

// progress spinner in button
.mdc-button__label {
  display: flex;
  align-items: center;
}

// Button size start
.mat-mdc-button-base {
  .mat-icon {
    width: var(--mdc-icon-button-icon-size);
    height: var(--mdc-icon-button-icon-size);
    font-size: var(--mdc-icon-button-icon-size);
  }

  .mat-mdc-button-touch-target {
    height: var(--mdc-icon-button-state-layer-size) !important;
    width: var(--mdc-icon-button-state-layer-size) !important;
  }
}

.mat-mdc-button-base.mat-button-xs {
  --mdc-icon-button-state-layer-size: 24px;
  --mdc-icon-button-icon-size: 15px;
  line-height: var(--mdc-icon-button-icon-size);
}

.mat-mdc-button-base {
  --mdc-icon-button-icon-size: 18px; // centers loading indicator in button-icon
}

.mat-mdc-button-base.mat-error,
.mat-mdc-button-base.mat-mdc-button-disabled.mat-error {
  color: var(--md-sys-color-error-container);
  background-color: var(--md-sys-color-error);
}

.mat-icon-button-text {
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 0.8em;
  text-align: center;
  justify-content: center;
  align-items: center;
}
