app-root {
  --mdc-filled-text-field-container-color: var(--md-sys-color-surface-container);
  // --mdc-filled-text-field-active-indicator-color: var(--md-sys-color-on-surface-bright);
  --mdc-filled-text-field-active-indicator-color: var(--md-sys-color-surface-container-highest);
}
.mat-mdc-form-field {
  width: 100%;

  .mat-mdc-form-field-infix {
    width: auto;
  }
}

.mat-form-field-disabled .mat-mdc-form-field-text-prefix {
  color: var(--mdc-filled-text-field-disabled-label-text-color);
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>')
    no-repeat center center;

  .dark & {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="white" /></svg>')
      no-repeat center center;
  }
}

mat-hint a {
  color: inherit;
}
