.mat-mdc-menu-panel {
  --mat-menu-container-shape: 0.5rem;
  --mat-menu-container-color: var(--md-sys-color-surface-bright);
}
.mat-mdc-menu-content {
  --mat-menu-item-label-text-weight: 400;
  --mat-menu-item-icon-size: 18px;

  .mat-icon {
    font-size: var(--mat-menu-item-icon-size);
  }
}

.mat-mdc-menu-panel {
  max-height: min(500px, calc(100vh - 130px));
  @extend .custom-scrollbar;
}
