// Sortable header, gray-out not sorted
.mat-sort-header-container:not(.mat-sort-header-sorted) {
  color: var(--md-sys-color-outline);
}

// Sticky columns
.mat-mdc-table-sticky-border-elem-left {
  border-right: 1px solid color-mix(in srgb, var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) 20%, transparent);
}

.mat-mdc-table-sticky-border-elem-right {
  border-left: 1px solid color-mix(in srgb, var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) 20%, transparent);

  // Custom class .table-menu-trigger to add small height border
  &.table-menu-trigger {
    border-left: 0;

    &::before {
      content: '';
      width: 1px;
      height: calc(100% - 30px);
      background: color-mix(in srgb, var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) 20%, transparent);
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
    }
  }
}

// Custom class: is--clickable. Use this to highlight row on hover
.cdk-row.is--clickable {
  transition: background-color 0.1s;
  cursor: pointer;

  &:hover {
    background: var(--md-sys-color-surface-variant);
  }
}

// Make select checkbox and kebab menu column smaller
.cdk-column-SELECT.mat-column-SELECT,
.cdk-column-MENU.mat-column-MENU {
  width: 50px;
}
