//.text-surface-variant {
//  color: mat-color(on-surface-variant);
//}
//.text-outline {
//  color: mat-color(outline);
//}

$bg-colors: (
  'primary',
  'primary-container',
  'primary-fixed',
  'primary-fixed-dim',
  'secondary',
  'secondary-container',
  'secondary-fixed',
  'secondary-fixed-dim',
  'tertiary',
  'tertiary-container',
  'tertiary-fixed',
  'tertiary-fixed-dim',
  'error',
  'error-container',
  'surface-dim',
  'surface',
  'surface-bright',
  'surface-container-lowest',
  'surface-container-low',
  'surface-container',
  'surface-container-high',
  'surface-container-highest',
  'outline',
  'outline-variant',
  'inverse-surface',
  'inverse-on-surface',
  'inverse-primary',
  'scrim',
  'shadow'
);

$text-colors: (
  'primary',
  'on-primary',
  'on-primary-container',
  'on-primary-fixed',
  'on-primary-fixed-variant',
  'secondary',
  'on-secondary',
  'on-secondary-container',
  'on-secondary-fixed',
  'on-secondary-fixed-variant',
  'tertiary',
  'on-tertiary',
  'on-tertiary-container',
  'on-tertiary-fixed',
  'on-tertiary-fixed-variant',
  'error',
  'on-error',
  'on-error-container',
  'on-surface',
  'on-surface-variant',
  'inverse-on-surface',
  'inverse-surface',
  'outline',
  'outline-variant'
);

$extended-text-colors: ('info', 'warning', 'success');

@each $color in $bg-colors {
  .bg-#{$color} {
    background-color: mat-color($color) !important;
  }
}
@each $color in $text-colors {
  .text-#{$color} {
    color: mat-color($color) !important;
  }
}
@each $color in $extended-text-colors {
  .text-#{$color} {
    color: var(--md-extended-color-#{$color}) !important;
  }
}

.bg-transparent {
  background: transparent !important;
}
