.cm-content {
  color: var(--md-sys-color-on-surface);
}

.md-code {
  background-color: var(--md-sys-color-primary-container);
  display: inline-block;

  .cm-line &:only-child {
    //width: 100%;
    //max-width: calc(100% - 120px);
  }
}

.md-quote {
  display: inline-block;

  .cm-line &:only-child::first-letter {
    color: var(--md-extended-color-success, var(--md-sys-color-tertiary));
  }
}

.md-list {
  display: inline-block;

  .cm-line &:only-child::first-letter {
    color: var(--md-extended-color-success, var(--md-sys-color-tertiary));
  }

  & + &.md-code {
    width: 100%;
    &::first-letter {
      color: inherit;
    }
  }
}
