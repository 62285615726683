@keyframes delayedOverflow {
  from {
    overflow: hidden;
  }
  to {
    overflow: visible;
  }
}

// Fix for the overflow issue when the panel is expanded
// so froala editor popups are not cut off
.mat-expansion-panel.mat-expanded {
  animation: delayedOverflow 1ms 0.5s forwards;
}
