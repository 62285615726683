@keyframes bounce {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.6);
  }
}

.extension-icon {
  aspect-ratio: 1;
  background: var(--md-sys-color-primary-container);
  display: inline-flex;
  border-radius: 50%;
  //color: var(--md-sys-color-on-primary-fixed-variant);
  color: var(--md-sys-color-on-primary-container);

  svg {
    //width: 38%;
    width: 10cqh;
    margin: auto;
  }
}

.preview-media-overlay {
  @include abs(0);
  z-index: 1;
  background-color: color-mix(in srgb, currentColor, transparent 70%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &.is--loading {
    color: var(--md-sys-color-secondary);
  }

  .mat-icon {
    --size: 10cqh;
    width: var(--size);
    height: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--size) / 2);
    position: relative;
    overflow: visible;
    color: currentColor;

    &::before {
      content: '';
      background-color: color-mix(in srgb, currentColor, transparent 70%);
      border-radius: 50%;
      width: var(--size);
      height: var(--size);
      position: absolute;
    }
  }

  &.is--animating {
    .mat-icon::before {
      //transform: scale(2);
      animation: bounce 0.6s cubic-bezier(0.35, 0, 0.25, 1) alternate infinite;
    }
  }
}
