.filter-bar {
  .mat-tonal-button {
    --mdc-filled-button-container-color: #{mat-color(primary-container)};
    --mdc-filled-button-label-text-color: #{mat-color(on-primary-container)};

    &.is--checked {
      --mdc-filled-button-container-color: var(--md-sys-color-primary);
      --mdc-filled-button-label-text-color: var(--md-sys-color-on-primary);
    }
  }

  .field-filled-rounded {
    --mdc-filled-text-field-container-color: #{mat-color(surface)};

    .mdc-line-ripple {
      display: none;
    }

    .mdc-text-field--filled {
      border-radius: var(--mdc-filled-text-field-container-shape, var(--mat-app-corner-extra-small-top));
    }
  }

  .filter-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    display: flex;

    &.is--expanded {
      max-height: 200px;
    }
  }

  .date-filter-chip {
    .mat-mdc-chip-action-label {
      display: flex;
      align-items: center;
    }
  }

  .select-chip {
    .mat-mdc-chip-action-label {
      display: flex;
      align-items: center;
    }
  }
}
